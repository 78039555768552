<template>
  <div style="padding-bottom: 80px">
    <b-card
      no-body
      class="mb-2"
    >
      <b-card-header
        class="p-2"
        style="display:flex; align-items: center; justify-content: space-between"
      >
        <b-card-title class="font-medium-2 card-title">
          <feather-icon
            icon="FileIcon"
            size="18"
          />
          <span class="align-middle ml-50">
            Importar Arquivo
          </span>
        </b-card-title>
        <b-button
          variant="primary"
          style="margin-left: 1rem"
          class="mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :to="{ name: 'layouts-importacao-reversa' }"
        >
          Gerenciar Layout
        </b-button>
      </b-card-header>
      <hr class="my-0">
      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <validation-provider
              #default="validationContext"
              name="Arquivo de Importação"
              rules="required"
            >
              <b-form-group
                label="Arquivo de Importação"
                label-for="arquivoImportacao"
              >
                <b-form-file
                  ref="fileInput"
                  v-model="data.files"
                  multiple
                  placeholder="Escolha o arquivo"
                  drop-placeholder="Solte o arquivo aqui"
                  accept=".csv, .xml, .txt"
                  :state="getValidationState(validationContext)"
                />
                <span class="undermessage-error">
                  {{ validationContext.errors[0] }}
                </span>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <validation-provider
              #default="validationContext"
              name="Layout"
              rules="required"
            >
              <b-form-group
                label="Layout"
                label-for="layout"
              >
                <b-form-select
                  id="layout"
                  v-model="data.arquivoRequest.layoutId"
                  :options="layoutsOptions"
                  :state="getValidationState(validationContext)"
                />
                <span class="undermessage-error">
                  {{ validationContext.errors[0] }}
                </span>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="mostrarDepartamento"
            cols="12"
            md="3"
          >
            <validation-provider
              #default="validationContext"
              name="Departamento"
              :rules="{ required: temDepartamento }"
            >
              <b-form-group
                label="Departamento(Detinatário)"
                label-for="departamento"
              >
                <v-select
                  id="departamento"
                  v-model="data.arquivoRequest.clienteDepartamentoId"
                  :options="selectDepartamentos"
                  :reduce="val=>val.value"
                  :clearable="false"
                  :state="getValidationState(validationContext)"
                />
                <span class="undermessage-error">
                  {{ validationContext.errors[0] }}
                </span>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <validation-provider
              #default="validationContext"
              name="Serviço"
              rules="required"
            >
              <b-form-group
                label="Serviço"
                label-for="servico"
              >
                <v-select
                  id="servico"
                  v-model="data.arquivoRequest.servicoId"
                  :options="servicosOptions"
                  :reduce="val=>val.dados.servicoId"
                  :clearable="true"
                />
                <span class="undermessage-error">
                  {{ validationContext.errors[0] }}
                </span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="flex-row-reverse">
          <div
            style="display:flex; align-items: center; justify-content: center"
          >
            <b-button
              variant="primary"
              class="mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              Próximo
              <feather-icon
                icon="ArrowRightIcon"
              />
            </b-button>
          </div>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

export default {
  name: 'ArquivoImportacaoVue',
  props: {
    getValidationState: {
      type: Function,
      required: true,
    },
    data: {
      type: undefined,
      required: true,
    },
  },

  beforeMount() {
    const { clienteId, usuarioId } = JSON.parse(localStorage.getItem('userName'))
    const tipo = 'reversa'
    const store = this.$store
    store.dispatch('layout/findAllReversa', clienteId).then(layouts => store.dispatch('layout/setOptionsReversa', layouts))
    store.dispatch('departamento/findAllByUsuarioId', usuarioId)
    store.dispatch('servicos/findAllTipo', { clienteId, tipo }).then(servicos => store.dispatch('servicos/setOptions', servicos))
  },

  setup() {
    const layoutStore = createNamespacedHelpers('layout')
    const departamentoStore = createNamespacedHelpers('departamento')
    const servicoStore = createNamespacedHelpers('servicos')

    const state = reactive({
      layoutsOptions: layoutStore.useGetters(['layoutsOptionsReversa']).layoutsOptionsReversa,
      departamentosOptions: departamentoStore.useGetters(['departamentos']).departamentos,
      servicosOptions: servicoStore.useGetters(['servicosOptions']).servicosOptions,
      selectDepartamentos: [],
      temDepartamento: false,
      mostrarDepartamento: false,
    })

    watch(() => state.departamentosOptions, value => {
      state.temDepartamento = !!value[0].clienteDepartamentoId
      value.forEach(elemento => {
        state.selectDepartamentos.push({ label: elemento.nomeDepartamento,
          value: elemento.clienteDepartamentoId })
      })
    })

    return {
      ...toRefs(state),
    }
  },
}
</script>

<style lang="scss">

.modal-body {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 50rem;
}

</style>